<template>
  <div class="bootstrap-modal-no-jquery">
    <div class="modal" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Avsluta försäkringsskydd</h5>
            <button
              type="button"
              class="close"
              @click="closeModal"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <FormField :field="fields.requested_insurance_to_date"></FormField>
            <FormField
              :field="fields.insurance_company_information"
            ></FormField>
            <FormField :field="fields.type_of_change"></FormField>
            <FormField
              v-if="user.roles.municipality"
              :field="fields.send_to"
            ></FormField>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              @click="continueToCancel"
              :disabled="
                !this.fields.requested_insurance_to_date.value ||
                !this.fields.type_of_change.value
              "
            >
              Fortsätt
            </button>
            <button type="button" class="btn btn-secondary" @click="closeModal">
              Avbryt
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormField from "./form/FormField.vue";
import { axiosBase } from "@/axios-api";
import { useToast } from "vue-toastification";
export default {
  props: {
    objectDataId: Number,
  },
  components: {
    FormField,
  },
  data() {
    return {
      confirmed: false,
      user: this.$store.getters.userDetail,
      error_message: "",
      fields: {
        requested_insurance_to_date: {
          name: "cancellation_date",
          label: "Upphörandedatum",
          help: "När försäkringsskyddet för byggnaden ska upphöra anges önskat upphörandedatum i detta fält.",
          key: "requested_insurance_to_date",
          type: "date",
          max_length: null,
          warning: false,
          required: true,
          disabled: false,
          column_width: 12,
          force_linebreak: false,
          has_option: false,
          minValue: new Date().toISOString().split("T")[0],
        },
        insurance_company_information: {
          name: "insurance_company_information",
          label: "Upplysningar från försäkrad till försäkringsbolaget",
          help: "I detta fält uppfyller den försäkrade sin upplysningsplikt till försäkringsbolaget, när det är aktuellt.",
          key: "insurance_company_information",
          type: "textarea",
          max_length: null,
          warning: false,
          required: false,
          disabled: false,
          column_width: 12,
          force_linebreak: false,
          has_option: false,
        },
        type_of_change: {
          name: "type_of_change",
          label: "Orsak till uppsägning",
          key: "type_of_change",
          type: "select",
          max_length: null,
          warning: false,
          required: true,
          disabled: false,
          column_width: 6,
          force_linebreak: false,
          has_option: true,
          options: [
            {
              active: true,
              value: "Avyttring (försäljning)",
              description: "Avyttring (försäljning)",
            },
            {
              active: true,
              value: "Beslut om rivning",
              description: "Beslut om rivning",
            },
            {
              active: true,
              value: "Rivning genomförd",
              description: "Rivning genomförd",
            },
            {
              active: true,
              value: "Objektet totalskadat",
              description: "Objektet totalskadat",
            },
          ],
        },
        send_to: {
          name: "send_to",
          label: "Skicka begäran till",
          key: "send_to",
          type: "select",
          max_length: null,
          warning: false,
          required: true,
          disabled: false,
          column_width: 6,
          force_linebreak: false,
          has_option: true,
          options: [
            {
              active: true,
              value: "to_intermediary",
              description: "Förmedlare",
            },
            {
              active: true,
              value: "to_admin",
              description: "Admin",
            },
          ],
        },
      },
    };
  },
  mounted() {
    this.toast = useToast();
  },
  methods: {
    closeModal() {
      this.$emit("close-modal-event", this.confirmed);
    },
    async continueToCancel() {
      let response = await this.sendRequest();
      if (response) {
        this.confirmed = true;
        if (this.user.roles.kommunassurans)
          this.toast.success("Försäkringsskydd avslutad");
        else this.toast.success("Begäran om avslutad försäkringsskydd skickad");
        this.closeModal();
      }
    },

    async sendRequest() {
      let response = false;
      await axiosBase({
        url: `api/v1/insuranceobject/${this.objectDataId}/cancel_insurance/`,
        method: "post",
        data: {
          cancellation_date: this.fields.requested_insurance_to_date.value,
          insurance_company_information:
            this.fields.insurance_company_information.value,
          type_of_change: this.fields.type_of_change.value,
          send_to: this.fields.send_to.value
            ? this.fields.send_to.value
            : "to_admin",
        },
      })
        .then(() => {
          response = true;
        })
        .catch((error) => {
          if (error.response.data.non_field_errors)
            this.toast.error(error.response.data.non_field_errors[0]);
          else if (error.response.data.detail)
            this.toast.error(error.response.data.detail);
          else this.toast.error("Lyckades inte spara stängningsförfrågan");
        });
      return response;
    },
  },
};
</script>

<style scoped>
/* Override default value of 'none' */
.modal {
  display: block;
}
.modal-content {
  border-color: #0176ba;
  border-width: medium;
  margin-top: 37%;
}
</style>
