<script>
/* eslint-disable */
export default {
  props: {
    field: Object,
  },
  emits: ["updatedValue"],
};
</script>
<template>
  <div class="posting bold">{{ field.subTitle }}</div>
  <textarea
    v-model="field.value"
    :id="field.name"
    :disabled="field.disabled || field.locked"
    rows="4"
    v-bind:class="{
      'is-invalid': !!field.invalid,
      updated: !!field.updated,
      'awaiting-approval': !!field.awaitingApproval,
    }"
    @change="$emit('updatedValue', $event.target.value)"
  >
  </textarea>
</template>

<style scoped>
.bold {
  font-weight: bold;
}
textarea {
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
}
</style>
