<template>
  <div class="page-container">
    <div class="page-title">
      <h1>{{ title }}</h1>
    </div>
    <div class="col-xs-6 form-background">
      <form @submit.prevent class="needs-validation" novalidate>
        <div v-for="(section, index) in sections" v-bind:key="section">
          <FormSection
            :section="section"
            :index="index"
            @updatedValue="updated"
          />
        </div>
      </form>
    </div>
    <footer-container
      :isNew="false"
      :hasPendingChanges="pendingChanges"
      :isUpdate="false"
      :isApprove="true"
      :isModified="noOfChanges > 0"
      :isValid="isValid"
      @doValidation="doValidation"
      @execute="doApprove"
      @goToUpdate="goToUpdate"
    />
  </div>
</template>

<script>
import Base from "@/views/InsuranceInputBase.vue";
import FooterContainer from "../components/FooterContainer.vue";
import { validateInputMixin } from "@/components/mixins/validate-input.mixin";
import { axiosBase } from "@/axios-api";
import { useToast } from "vue-toastification";

import FormSection from "../components/form/FormSection.vue";

export default {
  extends: Base,
  components: {
    FooterContainer,
    FormSection,
  },
  mixins: [validateInputMixin],
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      changeMaster: 0,
      changeMasterStatus: "",
      pendingChanges: false,
    };
  },
  async mounted() {
    this.initBase();
    await this.initialize();
  },
  computed: {
    ctx: function () {
      return {
        new: false,
        notApproved: true,
        isIntern: this.isIntern,
        item: this.item,
        formOfInsurance: this.formOfInsurance,
      };
    },
    isIntern: function () {
      return this.user.roles.kommunassurans;
    },
    title: function () {
      if (this.formList.id)
        return this.formList.id.value + " - " + this.formList.name.value;
      return " ";
    },
  },
  methods: {
    async initialize() {
      this.coord = { lat: 0, lon: 0 };
      await this.loadItem();
      await this.getStructure();
      this.buildCompleteObjectInformation();
      this.initializePendingChanges();
      this.setAllFieldsLocked();
      this.checkItemAccess();
      this.setupCustomFormEvents();
    },
    goToUpdate() {
      this.$router.push({ name: "Update" });
    },
    doValidation(doWarning) {
      this.isValid = this.validate(doWarning);
    },
    async doApprove(data) {
      this.submitForm["send_to"] = data.send_to;
      const props = {
        id: this.$route.params.id,
        sendEmail: this.isIntern,
        successMessage: this.getSuccessToastMessage(),
      };
      await this.sendRequest(props);
    },
    getSuccessToastMessage() {
      if (this.isIntern) return "Ändringarna genomförda";
      else return "Ändringar godkända av Förmedlare och skickade till Admin";
    },

    checkItemAccess() {
      if (!this.pendingChanges) {
        this.toast.warning("Inga förändringar att godkänna");
        this.$router.push({
          name: "Details",
          params: { id: this.item.id },
        });
      } else if (this.user.roles.municipality) {
        this.toast.error("Detta objekt väntar på godkännande");
        this.$router.push({
          name: "Details",
          params: { id: this.item.id },
        });
      } else if (
        this.user.roles.intermediary &&
        this.changeMasterStatus === "at_admin"
      ) {
        this.toast.error("Du har ej behörighet att godkänna ändringarna");
        this.$router.push({
          name: "Details",
          params: { id: this.item.id },
        });
      }
    },
    async loadItem() {
      await axiosBase
        .get(`api/v1/insuranceobject/${this.$route.params.id}/`)
        .then(async (response) => {
          this.item = response.data;
          if (this.activeObjectType.id !== response.data["object_type"].id) {
            await this.$store.dispatch(
              "setActiveObjectType",
              response.data["object_type"].id
            );
          }
        })
        .catch((e) => {
          if (e.response.status === 404) {
            this.toast.error("Efterfrågat objekt saknas.");
            this.$router.push({
              name: "Main",
            });
          }
        });
    },
    setAllFieldsLocked() {
      for (let key in this.formList) this.formList[key].locked = true;
    },
    setupCustomFormEvents() {
      this.changeHandler.setUser(this.user);
      this.changeHandler.register(
        this.changeHandler.set_DynamicPropertyValues_ForAdmin,
        "initialize"
      );
      this.changeHandler.register(
        this.changeHandler.set_DynamicPropertyValues_ForUser,
        "initialize"
      );
      this.changeHandler.register(
        this.changeHandler.set_RequestedFormOfInsurance_Visible,
        "initialize"
      );
      this.changeHandler.register(
        this.changeHandler.set_RequestedFormOfInsurance_Required,
        "initialize"
      );
      this.changeHandler.register(
        this.changeHandler.set_FormOfInsurance_Visible,
        "initialize"
      );
      this.changeHandler.register(
        this.changeHandler.set_FirstRisk_BasedOn_FormOfInsurance,
        "initialize"
      );
      this.changeHandler.register(
        this.changeHandler.set_InsuranceAmount_BasedOn_FormOfInsurance,
        "initialize"
      );
      this.changeHandler.register(
        this.changeHandler.set_InsuranceFromDate_Placeholder,
        "initialize"
      );
      this.changeHandler.register(
        this.changeHandler.set_RequestedInsuranceToDate_Placeholder,
        "initialize"
      );
      this.changeHandler.register(
        this.changeHandler
          .set_RequestedInsuranceToDate_BasedOn_InsuranceFromDate,
        "initialize"
      );
      this.changeHandler.register(
        this.changeHandler.set_InsuranceToDate_Placeholder,
        "initialize"
      );
      this.changeHandler.register(
        this.changeHandler
          .set_RequestedInsuranceToDate_BasedOn_InsuranceFromDate,
        "initialize"
      );
      this.changeHandler.register(
        this.changeHandler
          .set_Required_InsuranceToDate_BasedOn_RequestedInsuranceToDate,
        "initialize"
      );
      this.changeHandler.register(
        this.changeHandler.set_UW_Value,
        "initialize"
      );
      this.changeHandler.register(
        this.changeHandler.set_UWDecisionDate_Value,
        "initialize"
      );
      this.changeHandler.handleEvent("initialize", this.formList);
    },
  },
};
</script>

<style>
#create {
  margin-left: 15px;
}

@media (min-width: 768px) {
  #edit {
    padding-right: 5px;
  }
}

@media (min-width: 1400px) {
  .multi-collapse {
    background-color: lightgreen;
  }
}
div.ol-unselectable ol-layers {
  margin-top: 22px;
}
div.ol-zoom ol-unselectable ol-control {
  margin-top: 22px;
}
.gul {
  padding-right: 20px;
  margin-left: 17%;
}
b#changelock.gul {
  margin-left: 102px;
}
select#intermediary.form-Mottagare {
  margin-top: 10px;
}

.grön {
  /*padding-left: 30%;*/
  padding-right: 20px;
}

.btn,
.btn-success {
  height: 38px;
  margin-top: 4px;
}

div.form-check.form-Mottagare {
  padding: 0 !important;
  margin-left: 30px;
}

#hittaKoordinator {
  margin-top: 32px;
}

.card-body {
  width: 50%;
}

.table > :not(caption) > * > * {
  padding: 0.3rem 0.3rem 0.3rem !important;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
  margin-right: 2px;
}

.form-select {
  width: 100% !important;
  overflow: auto;
}

.multi-collapse {
  overflow: auto;
}

.btn-icon {
  height: 14px;
  margin: 0 auto;
  margin-top: -3px;
}

.form-row > .form-row [class*="col-"] {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.form-row-inside {
  display: flex;
  flex-direction: row;
}

/*knapp till kommuner */
#edit {
  margin: 10px 0 10px 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.crateContainer {
  border-top: 1px solid #ced4da;
  background-color: white;
  position: sticky;
  bottom: 0;
}

.change-button-container {
  display: flex;
  justify-content: space-between;
}

.form-row {
  margin: 0 auto;
  display: flex;
}

div.form-row {
  margin-bottom: 0rem;
  padding: 0.375rem 0.75rem;
}
.Form-control {
  margin-top: 0 !important;
  margin-bottom: 15px;
  width: 100% !important;
  display: block;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0 !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
}

.input-group-text {
  background-color: #e9ecef;
  border: 0 solid #ced4da !important;
  border-radius: 0.25rem;
}

.card-edit {
  max-width: 35%;
  padding-right: 12px;
  margin-bottom: 40px;
  padding-top: 14px;
}
</style>
