<template>
  <div class="dashboard">
    <Sidebar />

    <div class="content">
      <b-container>
        <div class="page-title">
          <h4>Optionslistor</h4>
        </div>
        <b-row class="dropAttributes">
          <b-col class="col-12">
            <label for="object-type" class="color">Välj objekttyp</label>
            <select
              id="object-types"
              v-model="selectedObjectType"
              @change="getCategories"
            >
              <option :value="null" selected disabled hidden></option>
              <option :value="null">Gemensamma optionslistor</option>
              <option
                v-for="item in objectTypes"
                v-bind:key="item.id"
                class="dropdown-item"
                :value="item.id"
              >
                {{ item.description }}
              </option>
            </select>
          </b-col>
          <b-col class="col-12">
            <label for="category" class="color">Välj optionslista</label>
            <select
              v-model="selectedOptionList"
              id="category"
              @change="getAttributes($event.target.value)"
            >
              <option
                v-for="item in categories"
                v-bind:key="item.name"
                v-bind:value="item.name"
              >
                {{ item.label }}
              </option>
            </select>
          </b-col>
        </b-row>
        <div v-show="displayContent">
          <b-row>
            <div id="value-table">
              <table class="table table-striped table-hover table.bordered">
                <thead>
                  <tr class="header" scope="col">
                    <th scope="col">Kod</th>
                    <th scope="col">Beskrivning</th>
                    <th class="text-right"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="attribute in attributes"
                    v-bind:key="attribute.key"
                    v-bind:class="{ deactivated: !attribute.active }"
                  >
                    <td>{{ attribute.value }}</td>
                    <td>{{ attribute.description }}</td>
                    <td class="text-right">
                      <button
                        v-if="attribute.default_flag"
                        class="btn btn-secondary badge btn-default-flag-badge"
                        title="Defaultflagga"
                      >
                        <i class="fa fa-solid fa-flag"></i>
                      </button>
                      <button
                        class="btn btn-secondary badge"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Redigera"
                        :disabled="!attribute.active"
                        @click="openEditModal(attribute.id)"
                      >
                        <i class="fas fa-solid fa-pen"></i>
                      </button>
                      <button
                        class="btn btn-primary badge"
                        data-toggle="tooltip"
                        data-placement="top"
                        :title="attribute.active ? 'Inaktivera' : 'Aktivera'"
                        @click="toggleActive(attribute.id)"
                      >
                        <i
                          class="fas fa-solid"
                          v-bind:class="{
                            'fa-ban': attribute.active,
                            'fa-check': !attribute.active,
                          }"
                        ></i>
                      </button>
                      <button
                        @click="deleteAttribute(attribute.id)"
                        class="btn btn-danger badge"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Radera"
                      >
                        <i class="fas fa-trash-alt"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-row>
          <b-row class="mt-1">
            <div>
              <button
                class="btn btn-primary"
                type="button"
                @click="openAddNewModal"
              >
                Nytt värde
              </button>
            </div>
          </b-row>
        </div>
      </b-container>
    </div>
  </div>
  <div class="modal" v-if="showEditModal"></div>
  <edit-attribute-modal
    v-if="showEditModal"
    @save-event="saveEditModal"
    @cancel-event="hideEditModal"
    :description="editDescription"
    :default_flag="editDefaultFlag"
  />
  <div class="modal" v-if="showAddNew"></div>
  <new-attribute-modal
    v-if="showAddNew"
    @save-attribute="saveAddNewForm"
    @cancel-event="hideAddNewModal"
    :attributeCode="this.attributeCode"
    :attributeLabel="this.attributeLabel"
  />
</template>

<script>
import Sidebar from "../components/Sidebar.vue";
import { axiosBase } from "@/axios-api";
import EditAttributeModal from "@/components/EditAttributeModal.vue";
import NewAttributeModal from "../components/NewAttributeModal.vue";
import { useToast } from "vue-toastification";

export default {
  name: "Attributes",
  components: {
    Sidebar,
    EditAttributeModal,
    NewAttributeModal,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      displayContent: false,
      categories: [],
      attributes: [],
      attributeCode: "",
      attributeLabel: "",
      selectedOptionList: null,
      selectedObjectType: null,
      editDescription: "",
      editDefaultFlag: null,
      editId: 0,
      showEditModal: false,
      showAddNew: false,
    };
  },
  computed: {
    objectTypes: function () {
      return this.$store.getters.getObjectTypes;
    },
  },
  methods: {
    setSelectedObjectType(id) {
      this.selectedObjectType = id;
    },
    hideEditModal() {
      this.showEditModal = false;
    },
    hideAddNewModal() {
      this.showAddNew = false;
    },
    openAddNewModal() {
      this.showAddNew = true;
    },
    openEditModal(id) {
      this.editId = id;
      const attr = this.attributes.find((x) => x.id === id);
      this.editDescription = attr.description;
      this.editDefaultFlag = attr.default_flag;
      this.showEditModal = true;
    },
    async saveEditModal(event) {
      this.hideEditModal();
      await this.updateDescription(this.editId, event.description);
      this.updateDefaultFlag(this.editId, event.default_flag);
    },
    async saveAddNewForm(event) {
      //this.attributeCode = event.attributeCode;
      //this.attributeLabel = event.attributeLabel;
      await this.submitForm(event.attributeCode, event.attributeLabel);
      this.hideAddNewModal();
    },
    async submitForm(attributeCode, attributeLabel) {
      const attribute = {
        field_category: this.selectedOptionList,
        object_type: null,
        value: attributeCode,
        description: attributeLabel,
        default_flag: this.default_flag,
      };
      if (this.selectedObjectType !== 0)
        attribute["object_type"] = this.selectedObjectType;
      await axiosBase
        .post("api/v1/attributes/", attribute)
        .then(() => {
          // TODO pusha in i array för att slippa hämta allt igen? hur göra med order då?
          this.toast.success(
            "Värdet " +
              attribute.value +
              " - " +
              attribute.description +
              " tillagt"
          );
        })
        .catch((error) => this.handleError(error));
      await this.getAttributes(this.selectedOptionList);
    },
    async getCategories() {
      console.log(this.selectedObjectType);
      this.displayContent = false;
      this.selectedOptionList = null;
      this.attributes = [];
      await axiosBase
        .get(
          `api/v1/attributes/get_distinct_fields/?${
            this.selectedObjectType
              ? `object_type=${this.selectedObjectType}`
              : ""
          }`
        )
        .then((response) => {
          this.categories = response.data;
        })
        .catch((error) => this.handleError(error));
    },
    async getAttributes(category) {
      await axiosBase
        .get(
          `api/v1/attributes/?${
            this.selectedObjectType
              ? `object_type=${this.selectedObjectType}`
              : ""
          }&field_category=` + category
        )
        .then((response) => {
          this.attributes = response.data;
          this.displayContent = true;
        })
        .catch((error) => this.handleError(error));
    },
    async updateDescription(id, description) {
      const current = this.attributes.find((x) => id === x.id);
      if (current.description === description) return;
      await axiosBase
        .patch(`api/v1/attributes/${id}/`, { description: description })
        .then(() => {
          this.toast.success("Attributet har uppdaterats");
        })
        .catch((error) => this.handleError(error));
      await this.getAttributes(this.selectedOptionList);
    },
    async updateDefaultFlag(id, default_flag) {
      const current = this.attributes.find((x) => id === x.id);
      if (current.default_flag === default_flag) return;

      if (default_flag) {
        const currentDefaultFlag = this.attributes.find(
          (x) => x.default_flag === true && x.id !== id
        );
        if (currentDefaultFlag) {
          await axiosBase.patch(`api/v1/attributes/${currentDefaultFlag.id}/`, {
            default_flag: false,
          });
        }
      }
      await axiosBase
        .patch(`api/v1/attributes/${id}/`, {
          default_flag: default_flag,
        })
        .then(() => {
          this.toast.success("Förvalt värde för listan har uppdaterats");
        })
        .catch((error) => this.handleError(error));
      await this.getAttributes(this.selectedOptionList);
    },
    async toggleActive(id) {
      const item = this.attributes.find((x) => x.id === id);
      await axiosBase
        .patch(`api/v1/attributes/${id}/`, { active: !item.active })
        .then(() => {
          // TODO pusha in i array för att slippa hämta allt igen? hur göra med order då?
          const text = item.active
            ? "Värdet har inaktiverats"
            : "Värdet har aktiverats";
          this.toast.success(text);
        })
        .catch((error) => this.handleError(error));
      await this.getAttributes(this.selectedOptionList);
    },
    async deleteAttribute(id) {
      const item = this.attributes.find((x) => x.id === id);
      await axiosBase
        .delete(`api/v1/attributes/${id}/`)
        .then(() => {
          // TODO pusha in i array för att slippa hämta allt igen? hur göra med order då?
          this.toast.success(
            "Värdet " + item.value + " - " + item.description + " raderat"
          );
        })
        .catch((error) => this.handleError(error));
      await this.getAttributes(this.selectedOptionList);
    },
    handleError(error) {
      if (error.response) {
        // Request made and server responded
        let message = "";
        for (const property in error.response.data) {
          error.response.data[property].forEach(function (item) {
            message += item;
          });
        }
        this.toast.error(message);
      } else if (error.request) {
        // The request was made but no response was received
        this.toast.error("Något gick fel, vänligen försök igen senare");
      }
    },
  },
};
</script>

<style scoped>
.table > :not(caption) > * > * {
  padding: 0.3rem 0.3rem 0.3rem !important;
}

.header {
  position: sticky;
  z-index: 3;
  color: white;
  top: -2px;
  font-size: 15px;
}

label {
  padding-bottom: 5px;
}

.card {
  padding-left: 0.7rem !important;
}
div#value-table {
  overflow: auto;
  max-height: 550px;
}
.form-group {
  margin-bottom: 0 !important;
  margin-top: 14px;
}

.deactivated {
  text-decoration: line-through;
}

.btn-default-flag-badge {
  background-color: green;
  pointer-events: none;
}
</style>
