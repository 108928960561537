<template>
  <div class="bootstrap-modal-no-jquery">
    <div class="modal" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content d-flex flex-column">
          <div class="modal-header">
            <h5 class="modal-title">Lägg till nytt attribut</h5>
          </div>
          <form>
            <div class="modal-body mt-4">
              <div class="form-group col-3">
                <input
                  type="text"
                  class="Form-controls"
                  id="value"
                  placeholder="Kod"
                  v-model="attributeCode"
                />
              </div>
              <div class="form-group col-9">
                <input
                  type="text"
                  class="Form-controls"
                  id="code"
                  placeholder="Beskrivning"
                  v-model="attributeLabel"
                />
              </div>
            </div>
            <div class="form-group modal-footer">
              <button
                type="button"
                class="btn btn-primary"
                :disabled="!allInputs"
                @click="saveNew"
              >
                Lägg till
              </button>
              <button
                type="button"
                class="btn btn-secondary"
                @click="cancelModal"
              >
                Avbryt
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      attributeCode: "",
      attributeLabel: "",
    };
  },
  computed: {
    allInputs: function () {
      return !!(this.attributeCode && this.attributeLabel);
    },
  },
  methods: {
    cancelModal() {
      this.$emit("cancel-event");
    },
    saveNew() {
      this.$emit("save-attribute", {
        attributeCode: this.attributeCode,
        attributeLabel: this.attributeLabel,
      });
    },
  },
};
</script>
<style scoped>
/* Override default value of 'none' */
.modal {
  display: flex;
}
.modal-content {
  border-color: #0176ba;
  border-width: medium;
  margin-top: 37%;
}
</style>
