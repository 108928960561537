<template>
  <div class="map-container mb-1">
    <Map
      class="createMap"
      :coord_in="initial_coord"
      :enableClick="true"
      @new_coord="getCoordinates"
    ></Map>
  </div>
  <div class="form-row-inside">
    <div class="col-3">
      <div class="field">
        <label for="lat">Latitud</label>
        <input
          v-model="lat"
          type="number"
          v-bind:class="{
            'form-control': true,
            'awaiting-approval': !!field.awaitingApproval,
          }"
          id="lat"
          readonly
          @change="onChange"
        />
      </div>
    </div>
    <div class="col-3">
      <div class="field">
        <label for="lon">Longitud</label>
        <input
          v-model="lon"
          type="number"
          v-bind:class="{
            'form-control': true,
            'awaiting-approval': !!field.awaitingApproval,
          }"
          id="lon"
          readonly
          @change="onChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Map from "@/components/Map";
import { useToast } from "vue-toastification";
import FormField from "@/components/form/FormField.vue";
/* eslint-disable */
export default {
  components: {
    Map,
    FormField,
  },
  mounted() {
    this.toast = useToast();
    this.lat = 0;
    this.lon = 0;
    this.initial_coord = { lat: 0, lon: 0 };
  },
  data() {
    return {
      new_coord: null,
      initial_coord: { lat: 0, lon: 0 },
      lat: 0,
      lon: 0,
      coordSet: false,
    };
  },
  watch: {
    "realValue"(newVal, oldVal) {
      this.setCoordValue();
      if(!this.coordSet)
        this.initial_coord = { lat: this.lat, lon: this.lon };
    },
  },
  computed: {
    coord() {
      return { lat: this.lat, lon: this.lon };
    },
    latField() {
      return {
        name: "lat",
        label: "Latitude",
        type: "text",
        readonly: true,
        value: this.lat,
      };
    },
    lonField() {
      return {
        name: "lon",
        label: "Longitud",
        type: "text",
        readonly: true,
        value: this.lon,
      };
    },
    realValue (){
      return this.field.value;
    }
  },
  props: {
    field: Object,
  },
  emits: ["updatedValue"],
  methods: {
    setCoordValue() {
      if(this.realValue && this.realValue.split(' ').length === 2) {
        const split = this.realValue.split(' ');
        if(!isNaN(split[0]) && !isNaN(split[1])) {
          this.lat = split[0];
          this.lon = split[1];
        }
      }
    },
    getCoordinates(value) {
      this.new_coord = value;
      this.coordSet = true;
      const val = `${this.new_coord.lat} ${this.new_coord.lon}`;
      this.field.value = val;
      this.$emit("updatedValue", val);
    },
  },
};
</script>
<style>
.map-container #map {
  height: 300px;
}
</style>
<style scoped>

label {
  font-weight: 600;
  color: #0176ba;
}
.map-container {
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  left: 0;
  right: 0;
  width: 100%;
  min-height: 300px;
}
</style>