<template>
  <div class="bootstrap-modal-no-jquery">
    <div class="modal" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ header }}</h5>
            <button
              type="button"
              class="close"
              @click="closeModal"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>{{ body }}</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="saveChanges">
              Fortsätt
            </button>
            <button type="button" class="btn btn-secondary" @click="closeModal">
              Avbryt
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["body", "header"],
  data() {
    return {
      confirmed: false,
    };
  },
  methods: {
    closeModal() {
      this.$emit("close-modal-event", this.confirmed);
    },
    saveChanges() {
      this.confirmed = true;
      this.closeModal();
    },
  },
};
</script>

<style scoped>
/* Override default value of 'none' */
.modal {
  display: block;
}
.modal-content {
  border-color: #0176ba;
  border-width: medium;
  margin-top: 37%;
}
</style>
