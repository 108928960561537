<script>
import FormField from "./FormField.vue";

/* eslint-disable */
export default {
  components: {
    FormField,
  },
  emits: ["updatedValue"],
  props: {
    section: Object,
    index: "",
  },
  computed: {
    href() {
      return "#toggle-section-" + this.index;
    },
    sectionId() {
      return "toggle-section-" + this.index;
    },
  },
  methods: {
    getFieldClass(field) {
      if (field.column_width === "") {
        return "col-12 mb-1";
      }
      return "col-" + field.column_width + " mb-1";
    },
    updated(name, value) {
      this.$emit("updatedValue", name, value);
    },
  },
};
</script>
<template>
  <div class="section">
    <div
      class="section-header"
      data-bs-toggle="collapse"
      :href="href"
      role="button"
      aria-expanded="true"
      :aria-controls="sectionId"
    >
      <h3 class="section-title">
        <i class="fa fa-solid fa-plus"></i>
        <i class="fa fa-solid fa-minus"></i>
        {{ this.section.label }}
      </h3>
    </div>
    <div class="section-body collapse show" :id="sectionId">
      <div class="d-flex flex-wrap">
        <template v-for="field in this.section.fields" v-bind:key="field">
          <div
            v-if="field.visible === undefined || field.visible"
            :class="getFieldClass(field)"
          >
            <FormField :field="field" @updatedValue="updated"></FormField>
          </div>
          <div v-if="field.force_linebreak" class="col-12"></div>
        </template>
      </div>
    </div>
  </div>
</template>

<style scoped>
.section {
  border-radius: 6px;
  margin-bottom: 15px;
}

.section-header {
  border-bottom: 1px solid rgb(255, 255, 255);
  border-radius: 6px 6px 0 0;
  display: flex;
  justify-content: space-between;
  background-color: #cbe5e8;
  font-size: 1.4rem;
}

.section-title {
  margin-top: 0 !important;
  padding: 15px;
  color: rgb(89, 89, 89);
  font-size: 1.5rem;
}

.section-body {
  padding: 15px;
  background-color: #e1eff0;
  border-radius: 0 0 6px 6px;
}
.section-header[aria-expanded="true"] .fa-plus {
  display: none;
}
.section-header[aria-expanded="false"] .fa-minus {
  display: none;
}
</style>
