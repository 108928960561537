<script>
/* eslint-disable */
import TextAreaField from "@/components/form/TextAreaField.vue";
export default {
  props: {
    field: Object,
    postings: Array,
  },
  emits: ["updatedValue"],
  components: {
    TextAreaField,
  },
};
</script>
<template>
  <TextAreaField
    :field="field"
    @updatedValue="(n) => $emit('updatedValue', n)"
  />
  <div v-for="post in field.postings" v-bind:key="post.id" class="posting">
    <i>[{{ post.created_by.username }} {{ post.created_on }}]</i>
    <p>{{ post.text }}</p>
  </div>
</template>

<style scoped>
.bold {
  font-weight: bold;
}
.posting,
.posting * {
  width: 100%;
}
</style>
