<script>
import customFormEventHandler from "@/components/customFormEventHandler.js";
import { validateInputMixin } from "@/components/mixins/validate-input.mixin";
import { axiosBase } from "@/axios-api";
import { useToast } from "vue-toastification";

export default {
  name: "InsuranceInputBase",
  mixins: [validateInputMixin],
  computed: {
    isIntern: function () {
      return this.user.roles.kommunassurans;
    },
    formOfInsurance: function () {
      let form_of_insurance = "";
      if (this.formList.form_of_insurance) {
        const id = this.formList.form_of_insurance.value
          ? this.formList.form_of_insurance.value
          : this.formList.requested_form_of_insurance.value;

        const attr = this.attributes.find((x) => x.value == id);
        if (attr) form_of_insurance = attr.key;
      }
      return form_of_insurance;
    },
    activeObjectType: function () {
      return this.$store.getters.getActiveObjectType;
    },
  },
  data() {
    return {
      byIntermediary: false,
      user: this.$store.getters.userDetail,
      objectType: this.$store.getters.getActiveObjectType,
      item: {},
      changeMaster: 0,
      submitForm: {},
      changeHandler: new customFormEventHandler(),
      formList: {},
      sections: [],
      toast: null,
      isValid: false,
    };
  },
  methods: {
    initBase() {
      this.toast = useToast();
    },
    validate(doWarning) {
      if (doWarning) this.validateWarning(this.formList);
      return this.validateError(this.formList);
    },
    updated(key, new_value) {
      this.updatePropertyValue(key, new_value);
      this.changeHandler.handleEvent("update", this.formList);
    },
    updatePropertyValue(key, new_value) {
      this.formList[key].invalid = !this.validateField(this.formList[key]);
      this.formList[key].updated = this.item[key] !== new_value;
      if (this.formList[key].updated) {
        this.submitForm[key] = new_value;
      } else if (
        this.$helpers.inDict(key, this.submitForm) &&
        this.submitForm[key] !== new_value
      ) {
        delete this.submitForm[key];
      }
    },
    async sendRequest(props) {
      await axiosBase({
        url: `api/v1/insuranceobject/${props.id ? props.id + "/" : ""}`,
        method: props.id ? "patch" : "post",
        data: this.submitForm,
      })
        .then((response) => {
          if (props.sendEmail) this.sendMail(response.data.id);
          this.toast.success(props.successMessage);
          this.$router.push({
            name: "Details",
            params: { id: response.data.id },
          });
        })
        .catch((error) => {
          if (error.response.status === 400) {
            if (
              error.response.data.non_field_errors &&
              error.response.data.non_field_errors[0].includes("unikt set")
            ) {
              this.toast.error(
                `Det finns redan ett försäkrat objekt med samma värde för fältet: ${this.formList.name.label}.\nDetta måste vara unikt.`
              );
              this.formList.name.invalid = true;
            } else if (error.response.data) {
              let errors = Object.keys(error.response.data).reduce(
                (acc, key, index) =>
                  acc +
                  (index === 0 ? "" : "\n") +
                  this.getFieldTranslation(key) +
                  ": " +
                  error.response.data[key],
                ""
              );
              this.toast.error(errors);
            }
          } else {
            this.toast.error("Något gick fel, vänligen försök igen senare");
          }
        });
    },
    getFieldTranslation(name) {
      return this.formList[name].label;
    },
    async sendMail(id) {
      await axiosBase
        .post(`api/v1/insuranceobject/${id}/send_mail/`, {
          changeMaster: this.changeMaster,
        })
        .catch(() => this.toast.error("Lyckades inte att skicka mailet."));
    },
    async getStructure() {
      await axiosBase
        .get(`api/v1/fieldstructure/?object_type=${this.activeObjectType.id}`)
        .then((response) => {
          this.sections = response.data;
          for (const item of this.sections) {
            for (const field of item.fields) {
              this.formList[field.name] = field;
            }
          }
        });
    },
    hasSelectedOptionKey(field, key) {
      const currentId = this.submitForm[field] ?? this.formList[field].value;
      if (currentId > 0) {
        const attr = this.attributes.find((x) => x.value == currentId);
        if (attr) return attr.key === key;
      }
      return false;
    },
    async loadItem() {
      await axiosBase
        .get(`api/v1/insuranceobject/${this.$route.params.id}/`)
        .then((response) => {
          this.item = response.data;
          if (this.activeObjectType.id !== response.data["object_type"].id) {
            this.$store.dispatch(
              "setActiveObjectType",
              response.data["object_type"].id
            );
          }
        })
        .catch((e) => {
          if (e.response.status === 404) {
            this.toast.error("Efterfrågat objekt saknas.");
            this.$router.push({
              name: "Main",
            });
          }
        });
    },
    buildCompleteObjectInformation() {
      this.coord = {
        lat: this.item.coordinate_lat ? this.item.coordinate_lat : 0,
        lon: this.item.coordinate_lon ? this.item.coordinate_lon : 0,
      };
      for (let key in this.formList) {
        if (this.item[key]) {
          if (this.item[key].username) this.item[key] = this.item[key].username;
          else if (this.item[key].id)
            this.item[key] = this.item[key].id; // Sets the FK Id as value
          else if (this.formList[key].type === "date")
            this.item[key] = this.item[key].substring(0, 10);
          if (Array.isArray(this.item[key])) {
            this.formList[key].value = "";
            this.formList[key].postings = this.item[key];
          } else {
            this.formList[key].value = this.item[key];
          }
        } else {
          this.item[key] = this.formList[key].type === "date" ? null : "";
        }
      }
    },
    initializePendingChanges() {
      if (this.item.changemaster.length) {
        this.pendingChanges = true;
        const cm = this.item.changemaster[0];
        this.changeMasterStatus = cm.status;
        this.changeMaster = cm.id;
        for (let i = 0; i < cm.changelog.length; i++) {
          if (this.$helpers.inDict(cm.changelog[i].field, this.formList)) {
            this.formList[cm.changelog[i].field].awaitingApproval = true;
          }
        }
      }
    },
  },
};
</script>
