<template>
  <div class="dashboard">
    <Sidebar />

    <div class="content">
      <p>
        <a
          id="addMunicipalityPlus"
          class="btn btn-primary"
          data-bs-toggle="collapse"
          href="#multiCollapse"
          role="button"
          aria-expanded="false"
          aria-controls="multiCollapse"
          >Lägga till kommuner</a
        >
      </p>
      <div class="row">
        <div class="col">
          <div class="collapse LaggTillKommun" id="multiCollapse">
            <div class="card card-kommun" id="municipalityForm">
              <form @submit.prevent="submitForm">
                <div class="form-group">
                  <label for="name">Namn</label>
                  <input
                    type="text"
                    class="Form-controls"
                    id="name"
                    placeholder="Kommunens namn"
                    v-model="municipality_name"
                  />
                </div>
                <div class="form-group">
                  <label for="OrgNr">Organisationsnummer</label>
                  <input
                    type="text"
                    class="Form-controls"
                    id="OrgNr"
                    placeholder="Organisationsnummer"
                    v-model="municipality_org_no"
                  />
                </div>
                <button type="submit" class="btn btn-primary" id="buttonKommun">
                  Lägg till
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <b-row>
        <b-col>
          <div class="show-table-kommun">
            <table class="table table-striped table-hover table.bordered">
              <thead>
                <tr class="header" scope="col">
                  <th scope="col">Namn</th>
                  <th scope="col">Org Nr</th>
                  <th scope="col">Kommun aktiv</th>
                  <th class="text-right"></th>
                </tr>
              </thead>
              <tbody class="paddingbox">
                <tr
                  v-for="municipality in municipalities"
                  v-bind:key="municipality.id"
                  :class="{
                    current: municipality.id === selectedMunicipality,
                  }"
                >
                  <td>
                    {{ municipality.name }}
                  </td>
                  <td>{{ municipality.org_no }}</td>
                  <td>
                    <b-form-checkbox
                      id="checkbox-1"
                      v-model="municipality.is_active"
                      name="checkbox-1"
                      @change="
                        editMunicipality({
                          id: municipality.id,
                          change: {
                            is_active: municipality.is_active,
                          },
                        })
                      "
                    >
                    </b-form-checkbox>
                  </td>

                  <td class="text-right">
                    <button
                      @click="showUser(municipality.id)"
                      class="btn btn-danger badge bg-danger float-right"
                      v-if="municipality.users.length"
                    >
                      <i class="fas fa-trash-alt"></i>
                      Visa användare
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-col>
        <b-col>
          <div class="show-table-kommun">
            <table class="table table-striped table-hover table.bordered">
              <thead>
                <tr class="header" scope="col">
                  <th scope="col">Email</th>
                  <th scope="col">Namn</th>
                  <th class="text-right"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="interUser in interUsers" v-bind:key="interUser.id">
                  <td>{{ interUser.username }}</td>
                  <td>
                    {{ interUser.first_name + " " + interUser.last_name }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-col>
      </b-row>

      <br />
      <br />
    </div>
  </div>
</template>

<script>
import { axiosBase } from "@/axios-api";
import Sidebar from "../components/Sidebar.vue";
import { useToast } from "vue-toastification";
import axios from "axios";

export default {
  name: "Municipality",
  components: {
    Sidebar,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      municipalities: [],
      municipality_name: "",
      municipality_org_no: "",
      users: "",
      interUsers: "",
      selectedMunicipality: "",
    };
  },
  beforeMount() {
    this.getSelectData();
  },
  methods: {
    async getSelectData() {
      // Run in parallel
      await axios
        .all([
          axiosBase.get(`api/v1/municipalityadmin/`),
          axiosBase.get(`auth/users/`),
        ])
        .then(
          axios.spread((data1, data2) => {
            // output of req.
            this.municipalities = data1.data;
            this.users = data2.data;
          })
        )
        .catch(() => {});
    },

    async showUser(municipalityId) {
      // Run in parallel
      this.selectedMunicipality = municipalityId;
      await axiosBase
        .get(`api/v1/municipalityadmin/${this.selectedMunicipality}/`)
        .then((response) => {
          // output of req.
          this.interUsers = response.data.users;
        })
        .catch((error) => {
          if (error.response) {
            // Request made and server responded
            let message = "";
            for (const property in error.response.data) {
              error.response.data[property].forEach(function (item) {
                message += item;
              });
            }
            this.toast.error(message);
          } else if (error.request) {
            // The request was made but no response was received
            this.toast.error("Något gick fel, vänligen försök igen senare");
          }
        });
    },
    editMunicipality(data) {
      return axiosBase
        .patch(`api/v1/municipalityadmin/${data.id}/`, data.change)
        .then((response) => {
          let message;
          if ("destroy" in data.change) {
            message = "User removed from " + response.data.name;
          } else {
            message =
              response.data.name +
              " kommun " +
              (response.data.is_active
                ? "är aktiverad"
                : "är inaktiverad" + "!");
          }

          this.toast.success(message);
        })
        .catch((error) => {
          if (error.response) {
            // Request made and server responded
            let message = "";
            for (const property in error.response.data) {
              error.response.data[property].forEach(function (item) {
                message += item;
              });
            }
            this.toast.error(message);
          } else if (error.request) {
            // The request was made but no response was received
            this.toast.error("Något gick fel, vänligen försök igen senare");
          }
        });
      // await this.getUsers; not needed?
    },
    async submitForm() {
      const municipality = {
        name: this.municipality_name,
        org_no: this.municipality_org_no,
      };
      await axiosBase
        .post("api/v1/municipalityadmin/", municipality)
        .then((response) => {
          this.toast.success(response.data.name + " kommun tillagd!");
        })
        .catch((error) => {
          if (error.response) {
            // Request made and server responded
            let message = "";
            for (const property in error.response.data) {
              error.response.data[property].forEach(function (item) {
                message += item;
              });
            }
            this.toast.error(message);
          } else if (error.request) {
            // The request was made but no response was received
            this.toast.error("Något gick fel, vänligen försök igen senare");
          }
        });
      await this.getSelectData();
    },
  },
};
</script>

<style scoped>
@media (min-width: 768px) {
  a#addMunicipalityPlus {
    margin-left: 20px !important;
    margin-top: 62px;
  }
  .LaggTillKommun {
    margin-left: 18px;
  }
  .dashboard {
    height: 178vh;
  }
}
@media (min-width: 1400px) {
  a#addMunicipalityPlus {
    margin-left: 35px !important;
    margin-top: 40px;
  }
  .LaggTillKommun {
    margin-left: 30px !important;
  }
  div#multiCollapse.LaggTillKommun.collapse.show {
    margin-left: 35px;
  }
  .dashboard {
    height: 100vh;
  }
}

.header {
  position: sticky;
  z-index: 3;
  color: white;
  top: -2px;
  font-size: 15px;
}
#municipalityForm {
  padding-left: 13px;
}
#buttonKommun {
  margin-bottom: 10px;
  margin-top: 10px;
}
button.btn btn-danger.badge.bg-danger.float-right {
  margin-left: 1px;
}
#addMunicipality {
  margin-left: 50px !important;
  margin-top: 3rem;
}

tr.current {
  font-weight: bold;
}

.dashboard {
  display: grid;
  grid-template-columns: 1fr 4fr;
  background-color: black;
}

.content {
  background-color: white;
  border-radius: 20px;
  margin: 6px 6px 6px 0;
}

.table {
  max-width: 90%;
  margin: 0 auto 100px;
}

.dropdown {
  max-width: 800px;
  margin: 100px auto 80px;
}

.Form-controls {
  margin-bottom: 15px;
  max-width: 85%;
  margin-top: 0 !important;
  width: 100% !important;
  display: block;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.show-table-kommun {
  overflow: scroll;
  height: 600px !important;
  width: 90%;
  margin: auto;
  margin-top: 24px !important;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.card {
  margin-left: 8px !important;
}

.card-kommun {
  max-width: 35%;
  padding-right: 12px;
  padding-top: 14px;
}
</style>
