export default class customFormEventHandler {
  constructor() {
    this.handlers = [];
  }
  setUser(user) {
    this.user = user;
  }

  register(method, event) {
    this.handlers.push({ event: event, execute: method });
  }

  handleEvent(event, item) {
    const ctx = { item: item, user: this.user };
    this.handlers
      .filter((x) => x.event === event)
      .forEach((x) => x.execute(ctx));
  }
  set_RequestedInsuranceFromDate(ctx) {
    if (
      ctx.item.requested_insurance_from_date &&
      !ctx.item.requested_insurance_from_date.value
    ) {
      ctx.item.requested_insurance_from_date.value =
        new Date().toLocaleDateString("sv-SE");
    }
  }
  set_RequestedInsuranceToDate_Placeholder(ctx) {
    if (ctx.item.requested_insurance_to_date)
      ctx.item.requested_insurance_to_date.placeholder = ctx.item
        .requested_insurance_from_date.value
        ? "Tillsvidare"
        : "";
  }
  set_UW_Value(ctx) {
    if (ctx.user.roles.kommunassurans && ctx.item["uw"])
      ctx.item["uw"].placeholder = ctx.user.username;
  }
  set_UWDecisionDate_Value(ctx) {
    if (ctx.user.roles.kommunassurans && ctx.item["uw_decision_date"])
      ctx.item["uw_decision_date"].placeholder = new Date().toLocaleDateString(
        "sv-SE"
      );
  }
  set_DynamicPropertyValues_ForAdmin(ctx) {
    if (!ctx.user.roles.kommunassurans) return;
    for (let key in ctx.item) {
      if (ctx.item[key].warning_admin) ctx.item[key].warning = true;
      if (ctx.item[key].required_admin) ctx.item[key].required = true;
    }
  }
  set_DynamicPropertyValues_ForUser(ctx) {
    if (ctx.user.roles.kommunassurans) return;
    for (let key in ctx.item) {
      if (ctx.item[key].required_nonadmin) ctx.item[key].required = true;
      if (ctx.item[key].disable_nonadmin) ctx.item[key].disable = true;
      if (ctx.item[key].warning_nonadmin) ctx.item[key].warning = true;
    }
  }
  set_Required_InsuranceToDate_BasedOn_RequestedInsuranceToDate(ctx) {
    if (!ctx.user.roles.kommunassurans) return;
    if (
      ctx.item.requested_insurance_to_date &&
      ctx.item.requested_insurance_to_date.value
    ) {
      ctx.item.insurance_to_date.required = true;
    }
  }
  set_InsuranceFromDate_Placeholder(ctx) {
    if (ctx.item.insurance_from_date)
      ctx.item.insurance_from_date.placeholder = "Ej försäkrad";
  }
  set_InsuranceToDate_Placeholder(ctx) {
    if (ctx.item.insurance_to_date)
      ctx.item.insurance_to_date.placeholder = ctx.item.insurance_from_date
        .value
        ? "Tillsvidare"
        : "Ej försäkrad";
  }
  set_CreatedBy_Placeholder(ctx) {
    if (ctx.item.created_by) {
      ctx.item.created_by.placeholder = ctx.user.username;
    }
  }
  set_CreatedOn_Value(ctx) {
    if (ctx.item.created_on && !ctx.item.created_on.value) {
      ctx.item.created_on.value = new Date().toLocaleDateString("sv-SE");
    }
  }
  set_RequestedFormOfInsurance_Visible(ctx) {
    if (ctx.item.requested_form_of_insurance) {
      ctx.item.requested_form_of_insurance.visible =
        ctx.user.roles.kommunassurans || !ctx.item.form_of_insurance.value;
    }
  }
  set_RequestedFormOfInsurance_Required(ctx) {
    if (ctx.user.roles.kommunassurans) return;
    if (
      ctx.item.requested_form_of_insurance &&
      !ctx.item.form_of_insurance.value
    ) {
      ctx.item.requested_form_of_insurance.required = true;
    }
  }
  set_FirstRisk_BasedOn_FormOfInsurance(ctx) {
    const field_to_check = !ctx.item.form_of_insurance?.value
      ? ctx.item.requested_form_of_insurance
      : ctx.item.form_of_insurance;
    if (!field_to_check) return;
    const first_risk_value = field_to_check?.options?.find(
      (x) => x.description == "Första risk"
    )?.value;
    if (ctx.item.first_risk) {
      ctx.item.first_risk.required = first_risk_value == field_to_check.value;
      ctx.item.first_risk.visible =
        ctx.user.roles.kommunassurans || ctx.item.first_risk.required;
    }
  }

  set_InsuranceAmount_BasedOn_FormOfInsurance(ctx) {
    const field_to_check = !ctx.item.form_of_insurance.value
      ? ctx.item.requested_form_of_insurance
      : ctx.item.form_of_insurance;
    if (!field_to_check) return;
    const first_risk_value = field_to_check.options?.find(
      (x) => x.description == "Första risk"
    )?.value;
    const full_value_value = field_to_check.options?.find(
      (x) => x.description == "Helvärde"
    )?.value;
    if (ctx.item.insurance_amount) {
      ctx.item.insurance_amount.required =
        [first_risk_value, full_value_value].indexOf(
          field_to_check.value?.toString()
        ) > -1;

      ctx.item.insurance_amount.visible =
        ctx.user.roles.kommunassurans || ctx.item.insurance_amount.required;
    }
  }
  set_FormOfInsurance_Visible(ctx) {
    if (ctx.item.form_of_insurance) {
      ctx.item.form_of_insurance.visible =
        ctx.user.roles.kommunassurans || !!ctx.item.form_of_insurance.value;
    }
  }

  set_PropertyDesignationMunicipality(ctx) {
    if (
      ctx.item.property_designation_municipality &&
      ctx.item.municipality.value &&
      !ctx.item.property_designation_municipality.value
    ) {
      ctx.item.property_designation_municipality.value =
        ctx.item.municipality.value;
    }
  }
  set_RequestedInsuranceToDate_BasedOn_InsuranceFromDate(ctx) {
    if (
      !ctx.item.insurance_from_date?.value &&
      ctx.item.requested_insurance_to_date
    ) {
      ctx.item.requested_insurance_to_date.disabled = true;
    }
  }
  set_DatePickerRestriction_ForUser(ctx) {
    if (ctx.user.roles.kommunassurans) return;
    for (let key in ctx.item) {
      if (
        ctx.item[key].type === "date" &&
        key !== "calculation_date" &&
        key !== "home_insurance_checkdate"
      )
        ctx.item[key].minValue = new Date().toISOString().split("T")[0];
    }
  }
}
