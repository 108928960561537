<script>
/* eslint-disable */
export default {
  props: {
    field: Object,
  },
  emits: ["updatedValue"],
};
</script>
<template>
  <input
    v-model="field.value"
    type="text"
    autocomplete="off"
    :maxlength="!!field.length ? field.length : ''"
    v-bind:class="{
      'form-control': true,
      'is-invalid': !!field.invalid,
      updated: !!field.updated,
      'awaiting-approval': !!field.awaitingApproval,
    }"
    :id="field.name"
    :placeholder="field.placeholder"
    :disabled="field.disabled || field.locked"
    :required="field.required"
    @change="$emit('updatedValue', $event.target.value)"
  />
</template>
