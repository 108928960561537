<script>
import TextInputField from "@/components/form/TextInputField.vue";
import EmailInputField from "@/components/form/EmailInputField.vue";
import DateInputField from "@/components/form/DateInputField.vue";
import NumberInputField from "@/components/form/NumberInputField.vue";
import CurrencyInputField from "@/components/form/CurrencyInputField.vue";
import SelectInputField from "@/components/form/SelectInputField.vue";
import TextAreaField from "@/components/form/TextAreaField.vue";
import TextAreaPostingsField from "@/components/form/TextAreaPostingsField.vue";
import InfoModal from "@/components/InfoModal.vue";
import CoordinateField from "@/components/form/CoordinateField.vue";

/* eslint-disable */
export default {
  components: {
    CoordinateField,
    InfoModal,
    TextInputField,
    DateInputField,
    NumberInputField,
    CurrencyInputField,
    EmailInputField,
    SelectInputField,
    TextAreaField,
    TextAreaPostingsField,
  },
  props: ["field", "values"],
  emits: ["updatedValue"],
  watch: {
    values: {
      immediate: true,
      handler() {
        this.setInitValue();
      },
    },
  },
  updated() {
    this.setInitValue();
  },
  data() {
    return {
      displayInfoModal: false,
      modal_type: "",
      modal_header: "",
      modal_content: "",
    };
  },
  methods: {
    setInitValue: function () {
      if (
        this.field.options &&
        !this.field.value &&
        !this.field.disabled &&
        this.$route.name === "Create"
      ) {
        const init = this.field.options.find((x) => x.default_flag);
        if (init) {
          this.field.value = init.value;
        }
      }
    },
    showInfoModal() {
      this.modal_header = this.field.label;
      this.modal_content = this.field.help;
      this.displayInfoModal = true;
    },
    hideInfoModal() {
      this.displayInfoModal = false;
      this.modal_header = "";
      this.modal_content = "";
    },
  },
};
</script>
<template>
  <div v-bind:class="{ required: !!field.required, field: true }">
    <label :for="field.name" class="color">{{ field.label }}</label>
    <button
      v-if="field.help"
      id="bklInfo"
      class="btn btn-link btn-icon btn-sm"
      @click="showInfoModal"
    >
      <i class="fas fa-info-circle"></i>
    </button>
    <CurrencyInputField
      v-if="field.type === 'currency'"
      :field="field"
      @updatedValue="(n) => $emit('updatedValue', field.name, n)"
    />
    <EmailInputField
      v-else-if="field.type === 'email'"
      :field="field"
      @updatedValue="(n) => $emit('updatedValue', field.name, n)"
    />
    <SelectInputField
      v-else-if="field.type === 'boolean'"
      :field="field"
      @updatedValue="(n) => $emit('updatedValue', field.name, n)"
    />
    <TextAreaField
      v-else-if="field.type === 'textarea'"
      :field="field"
      @updatedValue="(n) => $emit('updatedValue', field.name, n)"
    />
    <TextAreaPostingsField
      v-else-if="field.type === 'textareapostings'"
      :field="field"
      @updatedValue="(n) => $emit('updatedValue', field.name, n)"
    />
    <TextInputField
      v-else-if="field.type === 'text'"
      :field="field"
      @updatedValue="(n) => $emit('updatedValue', field.name, n)"
    />
    <NumberInputField
      v-else-if="field.type === 'number' || field.type === 'decimal'"
      :field="field"
      @updatedValue="(n) => $emit('updatedValue', field.name, n)"
    />
    <DateInputField
      v-else-if="field.type === 'date'"
      :field="field"
      @updatedValue="(n) => $emit('updatedValue', field.name, n)"
    />
    <SelectInputField
      v-else-if="field.type === 'select'"
      :field="field"
      @updatedValue="(n) => $emit('updatedValue', field.name, n)"
    />
    <CoordinateField
      v-else-if="field.type === 'coordinates'"
      :field="field"
      @updatedValue="(n) => $emit('updatedValue', field.name, n)"
    />
  </div>
  <info-modal
    v-if="displayInfoModal"
    :header="modal_header"
    :content="modal_content"
    @close-modal-event="hideInfoModal"
  />
</template>
<style scoped>
label {
  font-weight: 600;
  color: #0176ba;
}
.required label:after {
  content: "*";
  color: red;
}
.form-control {
  margin-top: 0 !important;
  margin-bottom: 15px;
  width: 100% !important;
  display: block;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.btn-icon {
  height: 14px;
  margin: 0 auto;
  margin-top: -3px;
}
</style>

<style>
.field .updated {
  font-weight: bold;
  background-color: papayawhip;
}

.field .awaiting-approval {
  color: red;
  font-weight: bold;
}
</style>
