<template>
  <div class="sidebar">
    <div class="title">Admin</div>

    <div class="menu-itmes">
      <router-link
        v-if="isAdmin || isIntermediary"
        :to="{ name: 'Inbox' }"
        active-class="active"
        exact
        tag="button"
        class="side-btn"
      >
        <div class="link-container">
          <i class="fas fa-inbox"></i>&nbsp;Inkorg
        </div>
      </router-link>

      <router-link
        v-if="isAdmin"
        :to="{ name: 'Municipality' }"
        active-class="active"
        exact
        tag="button"
        class="side-btn"
      >
        <div class="link-container">
          <i class="fas fa-building"> </i>&nbsp;Hantera kommuner
        </div>
      </router-link>
      <router-link
        v-if="isAdmin"
        :to="{ name: 'Users' }"
        active-class="active"
        exact
        tag="button"
        class="side-btn"
      >
        <div class="link-container">
          <i class="fas fa-user"> </i>&nbsp;Användare
        </div>
      </router-link>
      <router-link
        v-if="isAdmin"
        :to="{ name: 'Intermediary' }"
        active-class="active"
        exact
        tag="button"
        class="side-btn"
      >
        <div class="link-container">
          <i class="fas fa-house-user"> </i> Hantera Förmedlarhus
        </div>
      </router-link>

      <router-link
        v-if="isAdmin"
        :to="{ name: 'Attributes' }"
        active-class="active"
        exact
        tag="button"
        class="side-btn"
      >
        <div class="link-container">
          <i class="far fa-check-square"> </i> Hantera optionslistor
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "sidebar",
  data() {
    return {
      user: this.$store.getters.userDetail,
    };
  },
  computed: {
    isAdmin: function () {
      return this.user.roles.kommunassurans;
    },
    isIntermediary: function () {
      return this.user.roles.intermediary;
    },
  },
};
</script>

<style>
.fa-house-user:before {
  content: "\e065";
  padding-left: 15px;
}
.link-container,
* ::after {
  box-sizing: border-box;
  color: white;
}
.title {
  color: white;
  font-size: 25px;
  margin-top: 40px;
  font-weight: 600;
  padding-left: 15px;
}

a {
  font-weight: 100 !important;
}
.fa-inbox {
  color: white;
}
.fa-inbox:before {
  content: "\f01c";
  color: white;
  font-size: 20px;
  padding-left: 15px;
  padding-right: 5px;
}

.fa-building {
  color: white;
}

.fa-house-user:before {
  content: "\e065";
  padding-left: 15px;
  padding-right: 5px;
}

.fa-building:before {
  content: "\f1ad";
  color: white;
  font-size: 20px;
  padding-left: 15px;
  padding-right: 5px;
}

.fa-key {
  color: white;
}
.fa-key:before {
  content: "\f084";
  color: white;
  font-size: 20px;
  padding-left: 15px;
  padding-right: 5px;
}
.far {
  font-weight: 900 !important;
  padding-top: 3px;
}

.far {
  font-family: "Font Awesome 5 Free", Sans-serif, sans-serif;
}

.fa-user {
  color: white;
}
.fa-user:before {
  content: "\f007";
  color: white;
  font-size: 20px;
  padding-left: 15px;
  padding-right: 5px;
}

.fa-concierge-bell {
  color: white;
}
.fa-concierge-bell:before {
  content: "\f562";
  color: white;
  font-size: 20px;
  padding-left: 15px;
  padding-right: 5px;
}

.fa-check-square {
  color: white;
}
.fa-check-square:before {
  content: "\f14a";
  color: white;
  font-size: 20px;
  padding-left: 15px;
  padding-right: 5px;
}

.menu-itmes {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.menu-itmes {
  margin-top: 20px;
}

a.side-btn:hover,
a.side-btn:active {
  background-color: #0176ba;
}

a.active {
  background-color: var(--secondary);
}

.side-btn {
  border: none;
  padding: 25px 0;
  cursor: pointer;
  font-size: 15px;
  text-decoration: none;
}
</style>
