import jwtDecode from "jwt-decode";
import router from "../../router/index";
import { axiosBase } from "@/axios-api";
import axios from "axios";

const axiosAuth = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? process.env.VUE_APP_ROOT_API
      : "http://127.0.0.1:8000",
  timeout: 1000,
});

// https://stackoverflow.com/questions/61035923/send-silent-request-to-refresh-tokens
export default {
  state: {
    user: JSON.parse(sessionStorage.getItem("user")),
    authToken: {
      accessToken: null,
      refreshToken: null,
    },
    endpoints: {
      createToken: "/auth/jwt/create/",
      refreshToken: "/auth/jwt/refresh/",
      verifyToken: "/auth/jwt/verify/",
      userDetail: "/auth/users/me/",
    },
  },

  mutations: {
    UPDATE_USERDETAIL(state, userDetail) {
      const user = {
        username: userDetail.username,
        first_name: userDetail.first_name,
        last_name: userDetail.last_name,
        email: userDetail.email,
        isAdmin: userDetail.is_staff,
        group: userDetail.group.name,
        canEdit: userDetail.can_edit,
        hasIntermediary: userDetail.municipality.has_intermediary,
        roles: {
          kommunassurans: userDetail.group.name === "INTERN",
          intermediary: userDetail.group.name === "INTERMEDIARY",
          municipality: userDetail.group.name === "EXTERN",
        },
      };
      sessionStorage.setItem("user", JSON.stringify(user));
      state.user = user;
    },
    UPDATE_TOKEN(state, newToken) {
      axiosBase.defaults.headers.common["Authorization"] =
        "Bearer " + newToken.access;
      sessionStorage.setItem("accessToken", newToken.access);
      sessionStorage.setItem("refreshToken", newToken.refresh);
      state.authToken = {
        accessToken: newToken.access,
        refreshToken: newToken.refresh,
      };
    },
    UPDATE_STATUS(state, statusUpdate) {
      state.authStatus = statusUpdate;
    },
    REVOKE_TOKEN(state) {
      delete axiosBase.defaults.headers.common["Authorization"];
      sessionStorage.removeItem("accessToken");
      sessionStorage.removeItem("refreshToken");
      sessionStorage.removeItem("user");
      state.user = {};
      state.authToken = {
        accessToken: null,
        refreshToken: null,
      };
    },
  },

  getters: {
    isLoggedIn: (state) => {
      return state.authToken.accessToken != null;
    },
    userDetail: (state) => {
      return state.user;
    },
  },

  actions: {
    async login({ state, commit }, payload) {
      await axiosAuth
        .post(state.endpoints.createToken, {
          username: payload.email,
          password: payload.password,
        })
        .then((response) => {
          commit("UPDATE_TOKEN", response.data);
        })
        .catch(() => {});
      await axiosBase
        .get(state.endpoints.userDetail)
        .then((response) => {
          commit("UPDATE_USERDETAIL", response.data);
        })
        .catch(() => {});
    },

    register({ state, commit }, payload) {
      axiosAuth
        .post(state.endpoints.createToken, {
          username: payload.email,
          password: payload.password,
          first_name: payload.firstName,
          last_name: payload.lastName,
        })
        .then((response) => {
          commit("UPDATE_TOKEN", response.data);
        });
    },

    logout({ commit }) {
      commit("REVOKE_TOKEN");
    },

    refreshTokens({ state, commit }) {
      const refreshToken = sessionStorage.getItem("refreshToken");
      return axiosAuth
        .post(state.endpoints.refreshToken, { refresh: refreshToken })
        .then((response) => {
          commit("UPDATE_TOKEN", response.data);
        })
        .catch(async () => {
          commit("REVOKE_TOKEN");
          await this.router.push({ name: "Login" });
          // }
        });
    },

    verifyToken({ state, commit, dispatch, getters }) {
      const refreshToken = sessionStorage.getItem("refreshToken");
      if (refreshToken) {
        return axiosAuth
          .post(state.endpoints.verifyToken, { token: refreshToken })
          .then(async () => {
            // restore vuex state if it was lost due to a page reload
            if (!getters.isLoggedIn) {
              await dispatch("refreshTokens");
            }
          })
          .catch(() => {});
      } else {
        // if the token is not valid remove the local data and prompt user to login
        commit("REVOKE_TOKEN");
        router.push({ name: "Login" });
      }
    },

    async checkAccessTokenExpiry({ state, getters, dispatch }) {
      // inspect the store access token's expiration
      if (getters.isLoggedIn) {
        let accessToken = jwtDecode(state.authToken.accessToken);
        let nowInSecs = Date.now() / 1000;
        let isExpiring = accessToken.exp - nowInSecs < 30;
        // if the access token is about to expire
        if (isExpiring) {
          await dispatch("refreshTokens");
        }
      }
    },

    async refreshAccessToken({ dispatch, getters }) {
      /*
       * Check to see if the server thinks the refresh token is valid.
       * This method assumes that the page has been refreshed and uses the
       * @verifyToken method to reset the vuex state.
       */
      await dispatch("verifyToken");
      if (getters.isLoggedIn) {
        await dispatch("checkAccessTokenExpiry");
      }
    },

    async isLoggedIn({ getters, dispatch }) {
      /*
       * This method reports if the user has active and valid credentials
       * It first checks to see if there is a refresh token in local storage
       * To minimize calls it checks the store to see if the access token is
       * still valid and will refresh it otherwise.
       *
       * @isLoggedIn is used by the axios interceptor and the router to
       * ensure that the tokens in the vuex store and the axios Authentication
       * header are valid for page reloads (router) and api calls (interceptor).
       */
      let refreshToken = sessionStorage.getItem("refreshToken");
      if (refreshToken) {
        if (getters.isLoggedIn) {
          // TODO funkar denna dispath?
          await dispatch("checkAccessTokenExpiry");
        } else {
          await dispatch("refreshAccessToken");
        }
        return getters.isLoggedIn;
      }
      return false;
    },
  },
};
