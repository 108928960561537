<template>
  <div class="dashboard">
    <Sidebar />
    <div class="content">
      <p>
        <a
          id="addPropertyOwnerButton"
          class="btn btn-primary"
          data-bs-toggle="collapse"
          href="#addPropertyOwner"
          role="button"
          aria-expanded="false"
          aria-controls="addPropertyOwner"
          >Lägga till Fastighetsägare</a
        >
      </p>
      <div class="row">
        <div class="col">
          <div class="collapse" id="addPropertyOwner">
            <div class="card card-propertyowner">
              <form @submit.prevent="submitForm">
                <div class="form-group">
                  <label for="name">Namn</label>
                  <input
                    type="text"
                    class="Form-controls"
                    id="name"
                    placeholder="Namn"
                    v-model="name"
                  />
                </div>
                <div class="form-group">
                  <label for="OrgNr">Organisationsnummer</label>
                  <input
                    type="text"
                    class="Form-controls"
                    id="OrgNr"
                    placeholder="Organisationsnummer"
                    v-model="org_no"
                  />
                </div>
                <div>
                  <button type="submit" class="btn btn-primary" id="create">
                    Skapa Fastighetsägare
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="property_owners">
        <table class="table table-striped table-hover table.bordered">
          <thead>
            <tr class="header">
              <th scope="col">Namn</th>
              <th scope="col">Org Nr</th>
            </tr>
          </thead>
          <tbody class="paddingbox">
            <tr
              v-for="property_owner in property_owners"
              v-bind:key="property_owner.id"
            >
              <td>
                {{ property_owner.name }}
              </td>
              <td>{{ property_owner.org_no }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { axiosBase } from "@/axios-api";
import Sidebar from "../components/Sidebar.vue";
import { useToast } from "vue-toastification";
export default {
  name: "PropertyOwner",
  components: {
    Sidebar,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      property_owners: [],
      name: "",
      org_no: "",
    };
  },
  beforeMount() {
    this.getPropertyOwner();
  },
  methods: {
    async getPropertyOwner() {
      await axiosBase
        .get(`api/v1/propertyowner/`)
        .then((response) => {
          this.property_owners = response.data;
        })
        .catch(() => {
          this.toast.error(
            "Servern returnerade ett felmeddelande vid hämtning av befintliga fastighetsägare"
          );
        });
    },
    async submitForm() {
      const property_owner = {
        name: this.name,
        org_no: this.org_no,
      };
      await axiosBase
        .post("api/v1/propertyowner/", property_owner)
        .then(async (response) => {
          this.toast.success(
            "Fastighetsägare " + response.data.name + " skapad!"
          );
          await this.getPropertyOwner();
          this.name = "";
          this.org_no = "";
        })
        .catch((error) => {
          if (error.response) {
            // Request made and server responded
            let message = "";
            for (const property in error.response.data) {
              error.response.data[property].forEach(function (item) {
                message += item;
              });
            }
            this.toast.error(message);
          } else if (error.request) {
            // The request was made but no response was received
            this.toast.error("Något gick fel, vänligen försök igen senare");
          }
        });
    },
  },
};
</script>

<style scoped>
@media (min-width: 768px) {
  .dashboard {
    height: 155vh;
  }
}
@media (min-width: 1400px) {
  .dashboard {
    height: 86vh;
  }
}
.property_owners {
  overflow: scroll;
  height: 500px !important;
  width: 50%;
  margin-top: 21px !important;
  margin-left: 60px;
}
div.card.Card-bodys {
  margin-top: 20px;
}
.header {
  position: sticky;
  z-index: 3;
  color: white;
  top: -2px;
  font-size: 15px;
}
.card-propertyowner {
  max-width: 35%;
  padding-right: 12px;
  padding-top: 14px;
  padding-left: 13px;
}
#addPropertyOwnerButton {
  margin-left: 60px !important;
  margin-top: 57px;
}
#create {
  margin-left: 1px;
}
</style>
