import { axiosBase } from "@/axios-api";

// https://stackoverflow.com/questions/61035923/send-silent-request-to-refresh-tokens
export default {
  state: {
    active: Number(sessionStorage.getItem("objectType")),
    options: JSON.parse(sessionStorage.getItem("objectTypes")),
  },

  mutations: {
    SET_ACTIVE(state, id) {
      state.active = id;
      sessionStorage.setItem("objectType", id);
    },
    SET_OPTIONS(state, options) {
      state.options = options;
      sessionStorage.setItem("objectTypes", JSON.stringify(options));
    },
  },

  getters: {
    getActiveObjectType: (state) => {
      return state.options.find((x) => x.id === state.active);
    },
    getObjectTypes: (state) => {
      return state.options;
    },
  },

  actions: {
    async loadObjectTypes({ commit }) {
      await axiosBase.get("api/v1/objecttypes/").then((response) => {
        commit("SET_OPTIONS", response.data);
        commit("SET_ACTIVE", response.data[0].id);
      });
    },
    setActiveObjectType({ state, commit }, payload) {
      if (state.options.find((x) => x.id === payload))
        commit("SET_ACTIVE", payload);
    },
  },
};
