<template>
  <div>
    <Search />
  </div>
</template>

<script>
import Search from "../components/Search.vue";

export default {
  name: "Main",
  components: {
    Search,
  },

  beforeMount() {},
};
</script>

<style scoped></style>
