<template>
  <div id="app">
    <Log />
    <Navbar />

    <router-view />
  </div>
</template>

<script>
import { refreshPageMixin } from "./components/mixins/refresh-page.mixin";
import Navbar from "./components/Navbar.vue";
import Log from "./components/Log.vue";
import { useToast } from "vue-toastification";

export default {
  name: "app",
  mixins: [refreshPageMixin],
  components: {
    Log,
    Navbar,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  watch: {
    hashChanged: {
      immediate: true,
      handler(val, oldVal) {
        if (val && !oldVal)
          this.toast.info(
            "Ny version av kommunobjekt finns tillgänglig.\nTryck på F5 för att ladda om sidan.",
            { timeout: false }
          );
      },
    },
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.page-container {
  margin: 0 auto;
  width: 100%;
  max-width: 1900px;
  padding-left: 10px;
  padding-right: 10px;
}

.page-title {
  margin-left: 24px;
  margin-top: 48px;
  margin-bottom: 32px;
}

body #app {
  font-family: "montserrat", sans-serif;
}
</style>
