<template>
  <nav class="navbar navbar-expand">
    <div class="page-container">
      <div class="navbar-collapse" id="navbarText">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item" v-if="!loggedIn">
            <router-link class="nav-link" :to="{ name: 'Login' }">
              Logga in
            </router-link>
          </li>
          <li class="nav-item" v-if="loggedIn" v-click-outside="closeDropDown">
            <div class="btn-group mr-2">
              <button
                type="button"
                class="object-type object-type-button btn"
                @click="goToActiveObjectStartPage()"
              >
                {{ activeObjectType?.description }}
              </button>
              <button
                type="button"
                class="object-type nav-link btn object-type-arrow"
                @click="toggleDropDown()"
              >
                <i class="fa fa-solid fa-caret-down"></i>
              </button>
              <div class="dropdown-menu" :class="{ show: showDropDown }">
                <a
                  v-for="item in objectTypes"
                  v-bind:key="item.id"
                  class="dropdown-item"
                  @click="setActiveObjectType(item.id)"
                >
                  {{ item.description }}
                </a>
              </div>
            </div>
          </li>
          <li class="nav-item" v-if="user?.isAdmin">
            <router-link class="nav-link mr-2" :to="{ name: 'Inbox' }">
              Admin
              <span
                v-if="active_changes_count.count > 0"
                class="icon-button__badge"
                >{{ active_changes_count.count }}</span
              >
            </router-link>
          </li>
          <li class="nav-item" v-if="user?.group === 'INTERMEDIARY'">
            <router-link class="nav-link mr-2" :to="{ name: 'Inbox' }">
              Inkorg
              <span
                v-if="active_changes_count.count > 0"
                class="icon-button__badge"
                >{{ active_changes_count.count }}</span
              >
            </router-link>
          </li>
          <li class="nav-item" v-if="loggedIn">
            <a href="#" @click="logout()" class="nav-link">Logga ut</a>
          </li>
        </ul>
        <span class="navbar-text">
          <strong
            v-if="fullname"
            class="welcome"
            style="color: #212529"
            @click="changePassword()"
          >
            <span>Välkommen {{ fullname }}</span>
          </strong>
        </span>
      </div>
    </div>
  </nav>
</template>

<script>
import { axiosBase } from "@/axios-api";

export default {
  name: "navbar",
  data() {
    return {
      active_changes_count: {},
      user: {},
      loggedIn: false,
      showDropDown: false,
    };
  },
  watch: {
    $route(to, from) {
      if (to.path === "/") {
        this.logout();
        this.getUser();
      } else if (from.path === "/") {
        this.getUser();
        this.getActiveChangesCount();
      } else if (to.path !== "/") {
        this.getActiveChangesCount();
      }
    },
  },
  computed: {
    fullname: function () {
      const firstName = this.user?.first_name ?? "";
      const lastName = this.user?.last_name ?? "";
      return firstName + " " + lastName;
    },
    canEdit: function () {
      return this.user.canEdit;
    },
    activeObjectType: function () {
      return this.$store.getters.getActiveObjectType;
    },
    objectTypes: function () {
      return this.$store.getters.getObjectTypes;
    },
  },
  beforeMount() {},
  methods: {
    changePassword() {
      this.$router.push({ name: "Password" });
    },
    getUser() {
      // Retrieve user information and if user is logged in
      this.user = this.$store.getters.userDetail;
      this.loggedIn = this.$store.getters.isLoggedIn;
    },
    async getActiveChangesCount() {
      if (!this.user?.roles?.municipality) {
        await axiosBase
          .get("api/v1/changemaster/current/count")
          .then((response) => {
            this.active_changes_count = response.data;
          })
          .catch(() => {});
      }
    },
    toggleDropDown() {
      this.showDropDown = !this.showDropDown;
    },
    closeDropDown() {
      this.showDropDown = false;
    },
    async setActiveObjectType(id) {
      if (id !== this.activeObjectType.id) {
        await this.$store.dispatch("setActiveObjectType", id);
        this.$router.push({ name: "Main" });
      }
      this.toggleDropDown();
    },
    goToActiveObjectStartPage() {
      this.$router.push({ name: "Main" });
      this.closeDropDown();
    },
    async logout() {
      await this.$store.dispatch("logout");
      await this.$router.push({ name: "Login" });
    },
  },
};
</script>

<style scoped>
@media print {
  nav {
    display: none;
  }
}
nav {
  background-color: #0176ba;
}
.welcome {
  color: white !important;
  font-weight: 200 !important;
  cursor: pointer;
}
.welcome:hover span {
  display: none;
}
.welcome:hover:before {
  content: "Ändra lösenord";
}
.col-md-6 {
  margin-bottom: 1.5rem;
}
.header {
  background-color: #0176ba;
  height: 56px;
  margin-top: 50px;
}

.nav-item {
  align-content: center;
}

.btn-group {
  align-content: center;
}

button {
  margin-bottom: 3px !important;
  font-weight: 100;
}

li a {
  background-color: white;
  color: #0176ba;
  border: solid 1px #0176ba;
  border-radius: 6px;
  box-shadow: white;
}

.object-type {
  height: 42px;
}

.object-type-button {
  border: 1px solid #fff;
  background-color: inherit;
  color: #fff;
}

.object-type-button:hover {
  border: 1px solid #0176ba;
  color: #fff;
}

.object-type-arrow {
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
  background-color: white;
  color: #0176ba;
  box-shadow: white;
}

.object-type-arrow:hover {
  color: #fff;
}

li a:hover {
  background-color: #0176ba;
  color: white;
  text-decoration: none;
}

.dropdown-item {
  text-decoration: none;
  border: none;
}

.icon-button:hover {
  cursor: pointer;
}

.icon-button:active {
  background-color: #cccccc;
}

.icon-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  color: #333333;
  background-color: #dddddd;
  border: none;
  outline: none;
  border-radius: 50%;
}

.icon {
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
}
.icon-button__badge {
  background-color: red;
  color: #ffffff;
  display: flex;
  justify-content: center;
  text-decoration: none;
  border-radius: 80%;
  margin-left: 5px;
  width: 25px;
  float: right;
}
</style>
