<template>
  <div class="dashboarD">
    <Sidebar />

    <div class="content">
      <a
        id="laggaTillAnvandare"
        class="btn btn-primary"
        data-bs-toggle="collapse"
        href="#multiCollapseExample1"
        role="button"
        aria-expanded="false"
        aria-controls="multiCollapseExample1"
        >Skapa ny användare</a
      >
      <b-row>
        <div class="row" id="cardUser">
          <div class="collapse laggAnvandare" id="multiCollapseExample1">
            <div class="card Card-bodys" id="userForm">
              <form @submit.prevent="submitForm">
                <div class="validationTooltip04">
                  <label>Förnamn</label>
                  <b-form-input
                    type="text"
                    class="Form-controls"
                    id="name"
                    placeholder="Förnamn"
                    v-model="userForm.first_name"
                    required
                  ></b-form-input>
                </div>
                <div class="validationTooltip04">
                  <label>Efternamn</label>
                  <b-form-input
                    type="text"
                    class="Form-controls"
                    id="lastname"
                    placeholder="Efternamn"
                    v-model="userForm.last_name"
                    required
                  ></b-form-input>
                </div>
                <div class="validationTooltip04">
                  <label>E-post</label>
                  <b-form-input
                    type="email"
                    class="Form-controls"
                    id="email"
                    placeholder="E-post"
                    v-model="userForm.email"
                    required
                  ></b-form-input>
                </div>
                <div class="validationTooltip04">
                  <label>Password</label>
                  <b-form-input
                    type="password"
                    class="Form-controls"
                    id="password"
                    placeholder="Password"
                    v-model="userForm.password"
                    required
                  ></b-form-input>
                </div>
                <br />
                <button
                  :disabled="!allInputs"
                  type="submit"
                  class="btn btn-primary"
                  id="laggTillAnvandare"
                >
                  Lägg till
                </button>
              </form>
            </div>
          </div>
        </div>
      </b-row>
      <br />
      <br />
      <b-row>
        <b-col>
          <form @submit.prevent="searchUsers()" class="formSearch">
            <input
              name="search"
              type="text"
              class="form-control-main"
              v-model="search"
              placeholder="Sök"
              style="display: inline"
            />

            <button type="submit" class="btn btn-primary" id="sok">Sök</button>
          </form>
        </b-col>
      </b-row>

      <div id="show-table">
        <div class="tableUsers">
          <table class="table table-striped table-hover table.bordered">
            <thead>
              <tr class="header" scope="col">
                <th scope="col">Email</th>
                <th scope="col">Namn</th>
                <th scope="col">Behörighet</th>
                <th scope="col">Kommun</th>
                <th scope="col">Kan editera</th>
                <th scope="col">Aktiv</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="user in filtered_users"
                v-bind:key="user.id"
                id="userID"
              >
                <td>{{ user.username }}</td>
                <td>
                  {{ user.first_name + " " + user.last_name }}
                </td>
                <td>
                  <select
                    v-model="user.group.id"
                    @change="
                      editUser({
                        id: user.id,
                        change: {
                          user_group: user.group.id,
                        },
                      })
                    "
                  >
                    <option disabled value=""></option>
                    <option
                      class="buttonOne"
                      v-for="group in groups"
                      v-bind:key="group.key"
                      v-bind:value="group.key"
                    >
                      {{ group.label }}
                    </option>
                  </select>
                </td>
                <td class="editera">
                  <select
                    v-model="user.municipality.id"
                    @change="
                      editUser({
                        id: user.id,
                        change: {
                          user_municipality: user.municipality.id,
                        },
                      })
                    "
                  >
                    <option disabled value=""></option>
                    <option
                      class="buttonOne"
                      v-for="municipality in municipalities"
                      v-bind:key="municipality.id"
                      v-bind:value="municipality.id"
                    >
                      {{ municipality.name }}
                    </option>
                  </select>
                </td>
                <td>
                  <b-form-checkbox
                    id="checkbox-1"
                    v-model="user.can_edit"
                    name="checkbox-1"
                    @change="
                      editUser({
                        id: user.id,
                        change: {
                          user_can_edit: user.can_edit,
                        },
                      })
                    "
                  >
                  </b-form-checkbox>
                </td>
                <td class="editera">
                  <b-form-checkbox
                    id="checkbox-2"
                    v-model="user.is_active"
                    name="checkbox-2"
                    @change="
                      editUser({
                        id: user.id,
                        change: {
                          is_active: user.is_active,
                        },
                      })
                    "
                  >
                  </b-form-checkbox>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <br />
      <br />
    </div>
  </div>
</template>

<script>
import { axiosBase } from "@/axios-api";
import Sidebar from "../components/Sidebar.vue";
import { useToast } from "vue-toastification";

export default {
  name: "Users",
  components: {
    Sidebar,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      search: "",
      filtered_users: [],
      users: [],
      userForm: {
        first_name: "",
        last_name: "",
        username: "",
        email: "",
        password: "",
      },
      municipalities: [],
      groups: [
        { key: 1, label: "Extern" },
        { key: 2, label: "Förmedlare" },
        { key: 3, label: "Intern" },
        { key: 4, label: "Ej tilldelad" },
      ],
    };
  },
  computed: {
    allInputs: function () {
      return !!(
        this.userForm.first_name &&
        this.userForm.last_name &&
        this.userForm.email &&
        this.userForm.password
      );
    },
  },
  beforeMount() {
    this.getUsers();
    this.getMunicipalities();
  },
  methods: {
    searchUsers() {
      let tokens = this.search
        .toLowerCase()
        .split(" ")
        .filter(function (token) {
          return token.trim() !== "";
        });
      if (tokens.length) {
        //  Create a regular expression of all the search terms
        let searchTermRegex = new RegExp(tokens.join("|"), "gim");
        this.filtered_users = this.users.filter(function (user) {
          // Create a string of all object values
          let userString = "";
          for (let key in user) {
            // eslint-disable-next-line no-prototype-builtins
            if (user.hasOwnProperty(key) && user[key] !== "") {
              userString += user[key].toString().toLowerCase().trim() + " ";
            }
          }
          // Return book objects where a match with the search regex if found
          return userString.match(searchTermRegex);
        });
      } else {
        this.filtered_users = this.users;
      }
    },
    async getUsers() {
      await axiosBase
        .get("auth/users/")
        .then((response) => {
          this.users = response.data;
          this.filtered_users = this.users;
        })
        .catch(this.errorHandling);
    },
    async getMunicipalities() {
      await axiosBase
        .get(`api/v1/municipality/`)
        .then((response) => {
          this.municipalities = response.data;
        })
        .catch(this.errorHandling);
    },
    async submitForm() {
      this.userForm.username = this.userForm.email;
      await axiosBase
        .post("auth/users/", this.userForm)
        .then((response) => {
          this.toast.success(
            "Användare " + response.data.username + " skapad!"
          );
          this.userForm.first_name = "";
          this.userForm.last_name = "";
          this.userForm.email = "";
          this.userForm.password = "";
          this.getUsers();
        })
        .catch(this.errorHandling);
    },
    editUser(data) {
      return axiosBase
        .patch(`auth/users/${data.id}/`, data.change)
        .then((response) => {
          this.toast.success(
            "Användare " + response.data.username + " ändrad!"
          );
        })
        .catch(this.errorHandling);
      // await this.getUsers; not needed?
    },
    errorHandling(error) {
      if (error.response) {
        // Request made and server responded
        let message = "";
        for (const property in error.response.data) {
          error.response.data[property].forEach(function (item) {
            message += item;
          });
        }
        this.toast.error(message);
      } else if (error.request) {
        // The request was made but no response was received
        this.toast.error("Något gick fel, vänligen försök igen senare");
      }
    },
  },
};
</script>

<style>
@media (min-width: 768px) {
  a#laggaTillAnvandare.btn.btn-primary {
    margin-left: 26px !important;
    margin-top: 62px;
  }
  .laggAnvandare {
    margin-right: 20px;
  }
  #cardUser {
    margin-left: 38px;
  }
  .dashboarD {
    height: 200vh;
  }
}
@media (min-width: 1400px) {
  a#laggaTillAnvandare.btn.btn-primary {
    margin-left: 80px !important;
    margin-top: 52px;
  }
  #cardUser {
    margin-left: 91px;
    margin-top: 16px;
  }
  .dashboarD {
    height: 122vh;
  }
}
.table.table-striped.table-hover.table.bordered {
  margin-left: 60px !important;
}
input#checkbox-1.form-check-input {
  margin-bottom: 60px;
}
.interCLass {
  width: 82%;
  padding-left: 8px;
}
#show-table {
  overflow: scroll;
  height: 500px !important;
  width: 88%;
  margin: auto;
  margin-top: 62px;
  margin-right: 104px;
}
.header {
  position: sticky;
  z-index: 3;
  color: white;
  top: -2px;
  font-size: 15px;
}
#userForm {
  padding-left: 13px;
}
select#intermery {
  width: 50%;
  margin-left: 20px;
  padding-left: 8px;
}
#laggTillAnvandare {
  margin-bottom: 10px;
  margin-top: -15px;
}
.laggAnvandare {
  margin-bottom: -5rem;
}
.custom-select .mr-sm-2 {
  padding-bottom: 130px !important;
}
.card .card {
  margin-left: 70px !important;
}
.form-group {
  display: grid;
}

#laggaTillAnvandare {
  margin-left: 108px !important;
  max-width: 90%;
  margin-top: 3rem;
}
.labelName {
  padding-left: 10px;
}

.content {
  background-color: white;
  border-radius: 20px;
  margin: 6px 6px 6px 0;
}
.table {
  max-width: 90%;
  margin: 0 auto 100px;
}

.dropdown {
  max-width: 800px;
  margin: 100px auto 80px;
}
.dashboarD {
  display: grid;
  grid-template-columns: 1fr 4fr;
  background-color: black;
}
.Form-controls {
  margin-bottom: 15px;
  width: 91% !important;
  max-width: 95%;
  margin-top: 0 !important;
  display: block;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
.Card-bodys {
  max-width: 35%;
  padding-right: 3px;
  margin-bottom: 40px;
  padding-top: 40px;
  padding-bottom: 40px;
}
.table.table-striped.table-hover.table.bordered {
  margin-left: 26px !important;
}
.form-group {
  display: inline-block;
}
</style>
<style scoped>
.formSearch {
  margin-left: 87px;
}
</style>
