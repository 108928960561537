<template>
  <div class="box">
    <div class="boxOne">
      <br />

      <div class="row" id="'LoginForm">
        <legend class="col-md-6" id="ChangePassword">Ange Email</legend>
        <div class="col-md-3"></div>
        <div class="col-md-6" id="emailBox">
          <form @submit.prevent="submitForm" id="resetPassword">
            <input
              type="text"
              class="form-control"
              id="email"
              placeholder="Email"
              v-model="email"
              required
            />
            <button type="submit" class="btn btn-primary" required="">
              Återställ lösenord
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
import { axiosBase } from "@/axios-api";

export default {
  name: "ResetPassword.vue",
  data() {
    return {
      email: "",
    };
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  methods: {
    async submitForm() {
      await axiosBase
        .post("auth/users/reset_password/", { email: this.email })
        .then(async () => {
          this.toast.success(
            "Ett email har skickats till " +
              this.email +
              " om denna användaren finns"
          );
          await this.$router.push({ name: "Login" });
        })
        .catch((error) => {
          if (error.response) {
            // Request made and server responded
            let message = "";
            for (const property in error.response.data) {
              error.response.data[property].forEach(function (item) {
                message += item;
              });
            }
            this.toast.error(message);
          } else if (error.request) {
            // The request was made but no response was received
            this.toast.error("Något gick fel, vänligen försök igen senare");
          }
        });
    },
  },
};
</script>

<style scoped>
@media (min-width: 768px) {
  .box {
    margin-left: 450px;
    width: 50%;
    margin-top: 20px;
  }
}

@media (min-width: 1400px) {
  .box {
    margin-left: 40%;
    width: 35%;
    margin-top: 100px;
  }
}

.btn.btn-primary {
  margin-left: 2px !important;
}

#resetPassword {
  box-shadow: rgb(0 0 0 / 6%) 1px 1px 9px 1px;
  padding: 30px 20px;
}

#emailBox {
  padding-left: 0;
}
</style>
